






















import { Component, Vue } from 'vue-property-decorator';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
@Component({
  components: { BoxContent, Box },
})
export default class SubscribingResult extends Vue {}
