













































































































































import { Component, Inject, Vue } from 'vue-property-decorator';
import Breadcrumbs from '@/themes/v1/components/Breadcrumbs.vue';
import Box from '@/themes/v1/components/Box/Box.vue';
import BoxContent from '@/themes/v1/components/Box/BoxContent.vue';
import StatusLabel from '@/themes/v1/components/StatusLabel.vue';
import SubscribeOnProblem from './SubscribeOnProblem.vue';
import { getModule } from 'vuex-module-decorators';
import {
  AllowUnsubscribeStatus,
  SubscribingResult,
  TemporaryProblemsService,
  TemporaryProblemStatus,
  TemporaryProblemViewModel,
} from '@/modules/sts/temporary-problems';
import {
  SsrCtx,
  WithAsyncData,
  WithMeta,
  WithPrefetch,
} from '@/core/vue.types';
import { PlayerStore } from '@/themes/v1/stores/player.store';
import { resolveFromCtx } from '@/themes/v1/fns/inject-resolver';
import { universalRedirect } from '@/themes/v1/fns/router';
import SubscribingResultComponent from '@/themes/v1/views/sts/problems/SubscribingResult.vue';
import NeedSomethingElse from '@/themes/v1/components/NeedSomethingElse.vue';
import ProblemContent from '@/themes/v1/views/sts/problems/ProblemContent.vue';
import { executeWithoutError } from '@/themes/v1/fns/errors';
import { TicketsStore } from '@/themes/v1/stores/tickets.store';
import { TicketCreatorOptions } from '@/modules/sts/creator/creator-options.model';
import { TicketCreatorService } from '@/modules/sts/creator/tickets-creator.service';
import ScrollTop from '@/themes/v1/components/ScrollTop.vue';
import { SystemStore } from '@/core/system';
import { MetaInfo } from 'vue-meta';
import tail from 'lodash/tail';
import ProblemMessage from './components/ProblemMessage.vue';
import TestLocator from '@/core/test-utils/test-locator';

@Component({
  components: {
    ScrollTop,
    ProblemContent,
    NeedSomethingElse,
    SubscribingResultComponent,
    SubscribeOnProblem,
    StatusLabel,
    BoxContent,
    Box,
    Breadcrumbs,
    ProblemMessage,
  },
  directives: { TestLocator },
})
export default class ProblemView
  extends Vue
  implements WithAsyncData, WithPrefetch, WithMeta {
  @Inject()
  temporaryProblemsService!: TemporaryProblemsService;

  playerStore = getModule(PlayerStore, this.$store);
  systemStore = getModule(SystemStore, this.$store);

  problem: TemporaryProblemViewModel = {} as any;
  processing = false;
  result: SubscribingResult | null = null;
  platform?: string;
  showBackToListButton = true;
  showStatuses = false;

  get isClosed() {
    return this.problem.status === TemporaryProblemStatus.Closed;
  }

  get messagesButFirst() {
    return tail(this.problem.messages);
  }

  async asyncData(ctx: SsrCtx): Promise<any> {
    const platform = ctx.route.params.gamePlatform;
    const lang = ctx.route.params.lang;

    const ticketsStore = getModule(TicketsStore, ctx.store);
    let creationOptions: TicketCreatorOptions;
    if (ticketsStore.language == lang) {
      creationOptions = ticketsStore.options as TicketCreatorOptions;
    } else {
      const createrService = resolveFromCtx<TicketCreatorService>(
        'ticketCreatorService',
        ctx
      );
      creationOptions = await createrService.getOptions(lang);
      ticketsStore.setOptions({ lang, options: creationOptions });
    }
    const platformName = creationOptions?.platforms?.find(
      (x) => x.tag === platform
    )?.title;

    const temporaryProblemsService = resolveFromCtx<TemporaryProblemsService>(
      'temporaryProblemsService',
      ctx
    );
    const problem = await temporaryProblemsService.getSingle(
      ctx.app.$i18n.locale,
      ctx.route.params.problemId
    );
    const problemsInCurrentPlatform = await temporaryProblemsService.getList(
      ctx.app.$i18n.locale,
      platform
    );
    if (problem == null) {
      universalRedirect(ctx, `problems`);
      return {};
    }

    return {
      problem,
      platform: platformName,
      showBackToListButton: problemsInCurrentPlatform.length > 1,
    };
  }

  async prefetch(): Promise<void> {
    if (this.playerStore.isAuth) {
      const status = await executeWithoutError(() =>
        this.temporaryProblemsService.checkUnsubscribe(
          this.$route.params.problemId,
          this.playerStore.email
        )
      );
      if (status && status === AllowUnsubscribeStatus.Allowed) {
        this.result = SubscribingResult.AlreadySubscribed;
      }
    }
  }

  async onSubscribe(email: string) {
    try {
      this.processing = true;
      const playerStore = getModule(PlayerStore, this.$store);
      this.result = await this.temporaryProblemsService.subscribe(
        this.$i18n.locale,
        this.problem.id,
        email,
        playerStore.playerId,
        playerStore.loginId,
        this.$route.params.gamePlatform
      );
    } catch (e) {
      console.error(e);
    } finally {
      this.processing = false;
    }
  }

  metaInfo(): MetaInfo {
    return {
      title: this.$t('meta.createTicket.title') as string,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.$t('meta.createTicket.description') as string,
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.$t('meta.createTicket.keywords') as string,
        },
      ],
    };
  }
}
