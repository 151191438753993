export const executeWithoutError = async <T>(
  fn: () => Promise<T>,
  errHandler?: (e: any) => void | Promise<void>
): Promise<T | null> => {
  try {
    return await fn();
  } catch (e) {
    if (errHandler) {
      await errHandler(e);
    }
    return null;
  }
};
