var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"problems-view"},[_c('Breadcrumbs',{staticClass:"m-b--lv0 mobile-hide",attrs:{"current":_vm.problem.description.title}},[_c('li',[_c('router-link',{attrs:{"to":_vm.$routerHelper.withPlatformAndLang(_vm.$route, 'problems')}},[_vm._v(_vm._s(_vm.$t('phrases.problems')))])],1),_c('li',[_c('router-link',{attrs:{"to":_vm.$routerHelper.withPlatformAndLang(
            _vm.$route,
            ("problems/" + (_vm.$route.params.gamePlatform) + "/")
          )}},[_vm._v(_vm._s(_vm.platform))])],1)]),(_vm.showBackToListButton)?_c('router-link',{class:[
      'ticket-chat__back-btn mobile-only',
      _vm.$layout().ingame && 'm-t-xs--lv1' ],attrs:{"to":_vm.$routerHelper.withPlatformAndLang(
        _vm.$route,
        ("problems/" + (_vm.$route.params.gamePlatform))
      )}},[_c('i',{staticClass:"icon icon-arrow"}),_vm._v(" "+_vm._s(_vm.$t('phrases.back to list'))+" ")]):_vm._e(),_c('h1',{directives:[{name:"test-locator",rawName:"v-test-locator",value:('problem-title'),expression:"'problem-title'"}],staticClass:"mobile-hide"},[_vm._v(" "+_vm._s(_vm.problem.description.title)+" ")]),_c('div',{staticClass:"m-t--lv4"},[_c('Box',{staticClass:"problems-list__card m-b--lv2 p-t--lv2 p-b--lv2 p-t-xs--none",attrs:{"theme":"half","opacity":_vm.$bps('xs') ? 70 : 80,"disableTopDecoration":_vm.systemStore.device.isMobile,"disableBottomDecoration":_vm.systemStore.device.isMobile}},[_c('BoxContent',{staticClass:"problems-view__description mobile-column-inner--sm",attrs:{"without-splitter":"","space":"medium"}},[_c('h1',{directives:[{name:"test-locator",rawName:"v-test-locator",value:('problem-title'),expression:"'problem-title'"}],staticClass:"mobile-only m-b--lv1 m-b-xs--lv2"},[_vm._v(" "+_vm._s(_vm.problem.description.title)+" ")]),_c('ProblemContent',{attrs:{"content":_vm.problem.description.content}}),_c('h2',{staticClass:"problems-view__updates-title"},[_vm._v(" "+_vm._s(_vm.$t('pages.problems.updates'))+" ")])],1),(_vm.problem.messages != null && _vm.problem.messages.length > 0)?[_c('ProblemMessage',{attrs:{"status":_vm.problem.messages[0].problemStatusText,"date":_vm.problem.messages[0].dateSent,"message":_vm.problem.messages[0].content}})]:_vm._e(),(_vm.showStatuses)?_vm._l((_vm.messagesButFirst),function(message){return _c('ProblemMessage',{key:message.id,attrs:{"status":message.problemStatusText,"date":message.dateSent,"message":message.content}})}):_vm._e(),(
          _vm.showStatuses ||
          _vm.problem.messages == null ||
          _vm.problem.messages.length === 0
        )?_c('ProblemMessage',{attrs:{"status":_vm.problem.description.problemStatusText,"date":_vm.problem.dateOpened}}):_vm._e(),(_vm.problem.messages != null && _vm.problem.messages.length > 0)?_c('BoxContent',{staticClass:"problems-view__show-more",attrs:{"without-splitter":"","space":"none"}},[_c('a',{directives:[{name:"test-locator",rawName:"v-test-locator",value:('toggle-statuses'),expression:"'toggle-statuses'"}],staticClass:"highligthed-link",on:{"click":function($event){_vm.showStatuses = !_vm.showStatuses}}},[_vm._v(" "+_vm._s(_vm.showStatuses ? _vm.$t('pages.problems.hide-statuses') : _vm.$t('pages.problems.show-statuses'))+" ")])]):_vm._e()],2)],1),_c('div',{staticClass:"problem__actions"},[(_vm.isClosed)?_c('NeedSomethingElse',{attrs:{"path":"tickets/create"}}):(_vm.result == 'accepted')?_c('SubscribingResultComponent',[_c('h4',{staticClass:"problem-subscribe__title"},[_vm._v(" "+_vm._s(_vm.$t('pages.problems.successSubscribe'))+" ")])]):(_vm.result == 'alreadySubscribed')?_c('SubscribingResultComponent',[_c('h4',{staticClass:"problem-subscribe__title"},[_vm._v(" "+_vm._s(_vm.$t('pages.problems.alreadySubscribed'))+" ")])]):_c('SubscribeOnProblem',{attrs:{"disabled":_vm.processing},on:{"subscribe":function($event){return _vm.onSubscribe($event)}}})],1),_c('ScrollTop',{staticClass:"m-b-xs--lv0"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }